<template>
    <router-link :class="['card',`bg-${color}`]" :to="item.link">
        <div class="card__img">
            <img :src="item.image" :alt="item.title">
        </div>
        <div class="card__content">
            <h3>{{ item.title }}</h3>
            <p>{{ item.info }}</p>
        </div>
    </router-link>
</template>

<script>
    export default {
        name: 'KategorieCard',
        props: {
            item: {
                type: Object,
                required: true
            },
            color: {
                type: String,
                default: '',
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .card {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: 125px;
        height: auto;
        background-color: $primary;
        vertical-align: middle;
        box-shadow: 1px 2px 4px 0px #00000040;
        margin-bottom: 15px;
        border: none;
        border-radius: 0;

        &__img {
            background: $white-color;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-width: 125px;
            height: 125px;

            img {
                height: 65%;
                width: auto;
            }

        }

        &__content {

            padding: 0 15px;
            color: $white-color;

            h3 {
                color: $white-color;
                margin-bottom: 10px;
                font-size: 16px;
            }

            p {
                font-size: 13px;

                @media (max-width: 330px) {
                    font-size: 12px;
                }
            }
        }
    }
</style>
